import * as Sentry from "@sentry/browser"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()

	nuxtApp.provide("sentry", {
		$init: () => {
			if (runtimeConfig.public.SENTRY_LOGGING_ENABLED) {
				Sentry.init({
					environment: runtimeConfig.public.ENVIRONMENT,
					dsn: runtimeConfig.public.SENTRY_DSN,
					debug: false,
					maxValueLength: 4096,

					// Performance Monitoring
					tracesSampleRate: runtimeConfig.public.ENVIRONMENT === "PROD" ? 0.1 : 1, //You may want to change it to 100% while in development and then sample at a lower rate in production.

					// Session Replay
					replaysSessionSampleRate: runtimeConfig.public.ENVIRONMENT === "PROD" ? 0 : 1, // 0 will disable continuous recording. You may want to change it to 100% while in development and then sample at a lower rate in production.
					replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

					// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
					tracePropagationTargets: Object.values(runtimeConfig.public.SERVICES),

					integrations: [
						//Enable Performance Monitoring
						Sentry.browserTracingIntegration(),
						Sentry.captureConsoleIntegration({ levels: ["error", "warn"] }),
						//Enable Replay Recording. Mask credit card fields.
						Sentry.replayIntegration({
							maskAllText: false,
							maskAllInputs: false,
							//Allow Sentry to capture user entered information except the credit card info
							//https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/privacy/?original_referrer=https%3A%2F%2Fdocs.sentry.io%2F
							mask: ["#card-number", "#expiry-date", "#cvv"],
							//Allow Sentry to network information in the replays
							networkDetailAllowUrls: [window.location.origin, "cloudfront.net", "amazonaws.com"],
							networkResponseHeaders: ["Cache-Control", "Age", "Etag", "X-Amz-Cf-Id"],
						}),
					],
				})
			}
		},
	})
})
